//Home.js
import React, {useEffect, useMemo, useState} from 'react';
import { useForm } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";
import $ from 'jquery';
import Navbar from './Navbar'
import {Link, useNavigate, useParams} from "react-router-dom";
import './App.css';
import './Scripts.js';
import * as scripts from "./Scripts";
import Logo from './components/Logo'
import Login from "./Login";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Calculator from "./components/Calculator";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import ConnectionError from "./components/ConnectionError";
import styled from 'styled-components';
import {ArrowUpCircleFill} from '@styled-icons/bootstrap/ArrowUpCircleFill'
import {ArrowDownCircleFill} from '@styled-icons/bootstrap/ArrowDownCircleFill'
import {GenderAmbiguous} from "@styled-icons/bootstrap/GenderAmbiguous";
import PurpleLoader from './components/PurpleLoader';
import PageLoading from './components/PageLoading';
const ico_height = '25px';

const ArrowUpIco = styled(ArrowUpCircleFill)`
      height:${ico_height}
    `;

const ArrowDnIco = styled(ArrowDownCircleFill)`
      height:${ico_height}
    `;

const CustomSetup = () => {

    const [is_updating_order_qid, SetIsUpdateOrderQid] = useState(0);

    const [questions_to_delete, SetQuestionsToDelete] = useState({});
    const [questions_to_save, SetQuestionsToSave] = useState({});

    const { project_id } = useParams();
    const navigate = useNavigate();
    scripts.on_before_load_redirect_inside(navigate);

    useEffect(() => {
        scripts.fix_page_contents_class_height();

    },[]);


    const [data, setData] = useState(0);
    const [was_first_run, setfirstrun] = useState(0);
    const [added_at_index, setaddedindex] = useState(0);



    const [items, setItems] = useState([

        {uuid:0,
            qid:0,
            heading:"heading",
            content:"asdkasjdkajdkjasd"
        }
    ]);


    const [adtest_amount_left_to_pay, setCalculatedPrice] = useState(0);

    const [current_open_item, setCurrentOpenItem] = useState('');


    const fetchData = async () => {

        try {
            const response = await fetch(window.api_url+'adtest/main/get_adtest_custom_setup_data?project_id='+project_id+'&'+scripts.get_api_url_suffix()); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const data = await response.json();


            if(data.data.amount_left_to_pay){
                setCalculatedPrice(data.data.amount_left_to_pay);
            }



            setData(data);

            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }

    }


    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        fetchData();
    }, []);

    function get_first_run_val(){
        return was_first_run;
    }
    useEffect(() => {


        var run_number = get_first_run_val();

        if(run_number>=2){
            // $('.accordion__button').eq(added_at_index).click();

        }

        setfirstrun(run_number+1);


    }, [data]);



    function save_item(){

    }

    function copy_editable_to_short_form(gid) {


        var new_data = {};
        new_data.groups = [...data.groups];


        new_data.groups.forEach(function (group_item, this_index) {


            if (group_item.gid == gid) {




                var questions_short_form = [];

                group_item.editable_questions.forEach(function (q_item, inner_index) {

                    questions_short_form.push({
                       adtest_title:q_item.adtest_title,
                       qid:q_item.qid
                    });


                });

                group_item.questions_short_form = questions_short_form;

            }
        });


        setData(new_data);
    }
    function save_q_item_to_db(q_item) {
        console.log("saving question");
        console.log(q_item);



        if(questions_to_save[q_item.qid]){
            return;
        }


        var new_questions_to_save = {};

        Object.keys(questions_to_save).forEach(function(key) {

            new_questions_to_save[key] = 1;
        });

        new_questions_to_save[q_item.qid] = 1;

        SetQuestionsToSave(new_questions_to_save);


        var questions_order = [];





                const post_data = new FormData($('#update_question_'+q_item.gid+'_'+q_item.qid)[0]);
        data.groups.forEach(function (group_item, this_index) {


            //group being edited is found
            if (group_item.gid == q_item.gid) {

                    group_item.editable_questions.forEach(function (inner_q_item, inner_index) {


                        post_data.append('questions_order[]', inner_q_item.qid);




                    });

            }
        });


    console.log(data);

        $.ajax({
            url: window.api_url+'adtest/main/adtest_update_question?project_id='+project_id+'&'+scripts.get_api_url_suffix(),
            type: 'post',
            data:post_data,
            contentType: false,
            processData: false,
            success: function(response){

                    var new_questions_to_save = {};

                    Object.keys(questions_to_save).forEach(function(key) {


                        if(key!==q_item.qid)
                            new_questions_to_save[key] = 1;


                    });


                    SetQuestionsToSave(new_questions_to_save);


                try {
                    var json = $.parseJSON(response);





                    if(!json.success){
                        alert("please check your internet connection.");
                        return;
                    }


                    if(json.new_project_price){
                        setCalculatedPrice(json.new_project_price);
                    }

                    if(json.new_qid){
                        q_item.qid = json.new_qid;
                    }




                    var doesnt_qualify = "Doesn't Qualify if answer ";
                    var is_term = false;
                    $(q_item.answers).each(function(answer_key,answer) {


                        if(answer.is_term==1 || answer.is_term=="1"){


                            if(is_term){
                                doesnt_qualify+=" or";
                            }

                            doesnt_qualify+=" "+answer.answer;

                            is_term = true;


                        }

                    });


                    if(is_term)
                        q_item.doesnt_qualify = doesnt_qualify;
                    else {
                        q_item.doesnt_qualify = "";
                    }

                    if(json.adtest_title){
                        q_item.adtest_title = json.adtest_title;
                    }
                        set_question_in_data(q_item);
                        copy_editable_to_short_form(q_item.gid);



                }
                catch(err){
                    console.log(err);
                }

            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                alert("internet connection error, question was not saved.");
            }
        });



    }

    function do_change(items,open_group) {

        console.log("do change : ");
        console.log(items);

        var open_id = $('.accordion__button[aria-expanded="true"]').attr("id");



    var index = 0;
    $('#accordion_'+open_group+' .accordion__button').each(function(){
       var myid = $(this).attr("id");

       console.log(myid);

       if(myid=="accordion__heading-"+items[0]){


           var new_data = {};
           new_data.groups =[...data.groups];





           new_data.groups.forEach(function(group_item, outer_index) {


               if (group_item.gid == open_group) {


                   console.log("looking at group : ");
                   console.log(group_item);


                   var is_other_qid_open = false;


                   group_item.editable_questions.forEach(function(q_item, inner_index) {


                           if(q_item.is_open=="1" && inner_index!==index){
                               //user wants to save another question by opening another
                               console.log("open another");

                           }





                   });

        console.log("203");
                   group_item.editable_questions.forEach(function(q_item, inner_index) {

                       if(inner_index==index){

                           if(q_item.is_open=="1"){
                               //close the item clicked

                        if(can_q_item_close(q_item)){
                               q_item.is_open = "";
                               save_q_item_to_db(q_item);

                        }



                           } else {
                                //open item clicked






                               var continue_close = true;

                               group_item.editable_questions.forEach(function(inloop_q_item, inloop_inner_index) {




                                   if(inloop_q_item.is_open=="1"){

                                       if(can_q_item_close(inloop_q_item)){
                                           inloop_q_item.is_open = "";
                                           save_q_item_to_db(inloop_q_item);
                                       }

                                       else {
                                           continue_close = false;
                                       }

                                   }

                               });
        console.log("got now");
                                if(continue_close && q_item.type!=="AS" &&  q_item.type) {

                                    q_item.is_open = "1";

                                }


                           }



                       }


                   });


               }

           });



           setData(new_data);

       }


       index++;
    });




        if(open_id){
            console.log("is_open");
        } else {
            console.log("is_closed");
            if(current_open_item!=null){

                console.log("now lets save : "+current_open_item);

                //say the user has an error lets go back to this one

                $('.accordion__item').each(function(){

                    var button_element = $(this).find('.accordion__button');


                    if(button_element.attr("id")=="accordion__heading-"+current_open_item){
                setTimeout(function(){
                        // button_element.click();
                        button_element.addClass("testor");

                },1);
                    }


                });

            }


            setCurrentOpenItem(null);
        }

        try {

            var open_real_id = open_id.replace('accordion__heading-','');


            if(current_open_item!==open_real_id){
                if(current_open_item!=null)
                    console.log("now lets save : "+current_open_item);
            }


            setCurrentOpenItem(open_real_id);


        }
        catch(err){

        }



    }



    function add_item(){

        var new_items = [...items];


        var num_items = $('.accordion__button').length;

        new_items.push({
            uuid:num_items,
            qid:num_items,
            heading:"new_heading",
            content:"new_contents",
            question_title:"test"
        });

        setItems(new_items);




    }

    function set_item_field_data() {

    }
    function do_save_question(q_item){


        var new_data = {};

        new_data.groups =[...data.groups];


        var break_function = false;

        if(!can_q_item_close(q_item)){

            break_function = true;

        } else {
            save_q_item_to_db(q_item);
        }




            new_data.groups.forEach(function(group_item, this_index){



            if(group_item.gid==q_item.gid) {



                var should_stop = false;


                if(!break_function)
                    group_item.editable_questions.forEach(function(my_q_item, inner_index) {


                        if(my_q_item.qid==q_item.qid){
                            my_q_item.is_open = "";
                        }




                    });

            }
        });

        console.log(q_item);

    setData(new_data);

    }

    function fix_question_ids(editable_questions) {

        if(!editable_questions.toString()){
            return [];
        }

        editable_questions.forEach(function(value, index){


            try {
            if(value.adtest_title.indexOf('newquestion')!==-1){
                value.qid = "newqid"+index;
                value.adtest_title = "newquestion"+index;
            }

            }
            catch(err){

            }



        });

        return editable_questions;

    }



     function can_q_item_close(q_item){



        var errors = [];


        console.log(q_item);

         if(!q_item.media_title){
             errors.push("Question must have a title.");
         }

        if(!q_item.type){
            errors.push("please enter type and try again.");
        }



        if(q_item.type=='M' || q_item.type=='L'){

            if(!q_item.answers.length){
                errors.push("please add answers and try again.");
            } else {

                //we want to check that not all answers are term




                var did_find_non_qualifier_answer = false;

                //check end

                q_item.answers.forEach(function (answer_item, this_index) {


                    if(!answer_item.answer){

                          errors.push("answer item #"+this_index+" must have an answer");

                    }
                    if(answer_item.is_term=="0" || answer_item.is_term==0 || !answer_item.is_term){
                        did_find_non_qualifier_answer = true;
                    }




                });


                if(!did_find_non_qualifier_answer){
                    errors.push("Question must have an answer that will qualify; please revise.");
                }

            }

        }



            q_item.errors = errors;
        if(errors.length){

            $("#update_question_"+q_item.gid+"_"+q_item.qid+' input[name="media_title"]').focus();

            return false;
        }


        return true;

    };

    function add_question(e,gid,index) {
        console.log(e);
        e.stopPropagation();

        e.preventDefault();


        setaddedindex(index);
        console.log("adding at indx :"+index );

        var new_data = {};
        new_data.groups =[...data.groups];





        new_data.groups.forEach(function(group_item, this_index){



            if(group_item.gid==gid) {






                var max_questions = parseInt(group_item.max_questions);



            // if(group_item.editable_questions.length==max_questions){
            //     alert("you have reached the maximum number of questions, question cannot be added.");
            //     return;
            // }

                var should_stop = false;


                group_item.editable_questions.forEach(function(q_item, inner_index) {


                    if(q_item.is_open=="1"){

                        console.log("some open");

                        if(!can_q_item_close(q_item)){
                            should_stop = true;
                        } else {

                            save_q_item_to_db(q_item);
                            q_item.is_open = "";
                        }
                    }

                });


                if(should_stop){
                    return;
                }

                    var new_item ={
                    qid:"newquestion"+index,
                    adtest_title:"newquestion"+index,
                    gid:gid,
                    is_open:"1",
                        answers:[]
                };

                var editable_questions =

                    [
                        ...group_item.editable_questions.slice(0, index),
                        new_item,
                        ...group_item.editable_questions.slice(index)
                    ];

                console.log(editable_questions);
                group_item.editable_questions =editable_questions;



                group_item.editable_questions = fix_question_ids(group_item.editable_questions);


            }});


            setData(new_data);


            }



        function delete_question(e,qid,gid) {
        console.log(e);
        e.stopPropagation();

        e.preventDefault();

            console.log("del_q");

            var new_data = {};
            new_data.groups =[...data.groups];


            var delete_cb = function(){
                new_data.groups.forEach(function(group_item, this_index){


                    if(group_item.gid==gid){



                        var new_editable_questions =[];

                        group_item.editable_questions.forEach(function(q_item, this_index){


                            if(q_item.qid==qid){
                            } else {
                                new_editable_questions.push(q_item);
                            }

                        });





                        group_item.editable_questions = fix_question_ids(new_editable_questions);




                        var new_questions_short_form = [];


                        group_item.questions_short_form.forEach(function(q_item, this_index){


                            if(q_item.qid==qid){

                            } else {
                                new_questions_short_form.push(q_item);
                            }

                        });


                        group_item.questions_short_form = fix_question_ids(new_questions_short_form);




                    }

                });


                console.log(new_data);
                setData(new_data);
            };


            if((qid+"").indexOf("newqid")!==-1){
                delete_cb();
            }
            else {

                if(questions_to_delete[qid]){
                    return;
                }


                var new_questions_to_delete = {};

                Object.keys(questions_to_delete).forEach(function(key) {

                        new_questions_to_delete[key] = 1;
                });

                new_questions_to_delete[qid] = 1;

                SetQuestionsToDelete(new_questions_to_delete);


                $.ajax({
                        url: window.api_url+'adtest/main/remove_question?qid='+qid+"&remove_skip=1&project_id="+project_id+'&'+scripts.get_api_url_suffix(),
                        type: 'get',
                        contentType: false,
                        processData: false,
                        success: function(response){

                            try {
                                var json = $.parseJSON(response);

                                if(json.new_project_price || json.new_project_price==0){
                                    setCalculatedPrice(json.new_project_price);
                                }


                                if(json.error){
                                    alert(json.error);
                                    return;
                                }

                                if(json.success){

                                    var new_questions_to_delete = {};
                                    var found_real_id = false;

                                    Object.keys(questions_to_delete).forEach(function(key) {

                                        if(key!==qid)
                                            new_questions_to_delete[key] = 1;
                                    });


                                    SetQuestionsToDelete(new_questions_to_delete);


                                        delete_cb();
                                }

                            }
                            catch(err){
                                console.log(err);
                            }

                        },
                        error: function(XMLHttpRequest, textStatus, errorThrown) {
                            alert("internet connectionion problem. question was not deleted");
                        }
                    });
            }




    }

    function set_question_in_data(item) {
        var new_data = {};
        new_data.groups = [...data.groups];


        new_data.groups.forEach(function (group_item, this_index) {


            if (group_item.gid == item.gid) {


                group_item.editable_questions.forEach(function(q_item, inner_index) {



                    if(q_item.qid==item.qid)
                        q_item = item;

                });

                }


        });

        setData(new_data);

    }
    function set_question_value(e, item) {


        var name = e.target.name;
        
        item[name] = e.target.value;

        
        set_question_in_data(item);
        


    }

    function show_custom_setup_edit(e, gid) {

        var new_data = {};
        new_data.groups =[...data.groups];





        var break_function = false;

        new_data.groups.forEach(function(group_item, this_index) {



            if (group_item.gid == gid) {

                if(group_item.show_edit){


                    group_item.editable_questions.forEach(function(q_item, inner_index) {


                        if (q_item.is_open == "1") {
                            //close the item clicked

                            if (!can_q_item_close(q_item)){
                                break_function = true;
                            } else {
                                q_item.is_open = "";
                                set_question_in_data(q_item);
                                save_q_item_to_db(q_item);
                            }


                        }


                    });

                    if(break_function){
                        return;
                    }

                    group_item.show_edit = 0;


                } else {
                    group_item.show_edit = 1;

                }


            }


        });

        setData(new_data);

    }

    function save_questions_order_to_db_and_set_data(new_data,item){

        new_data.groups.forEach(function (group_item, this_index) {


            if (group_item.gid == item.gid) {


                var questions_order = [];
                const post_data = new FormData();

                group_item.editable_questions.forEach(function (inner_q_item, inner_index) {


                    post_data.append('questions_order[]', inner_q_item.qid);




                });


                post_data.append('sid',project_id);

                SetIsUpdateOrderQid(item.qid);
                $.ajax({
                    url: window.api_url+'adtest/main/adtest_update_questions_order?'+scripts.get_api_url_suffix(),
                    type: 'post',
                    data:post_data,
                    contentType: false,
                    processData: false,
                    success: function(response){
                        SetIsUpdateOrderQid(0);

                        try {
                            var json = $.parseJSON(response);




                            if(!json.success){
                                alert("please check your internet connection.");
                                return;
                            }

                         setData(new_data);

                        }
                        catch(err){
                            console.log(err);
                        }

                    },
                    error: function(XMLHttpRequest, textStatus, errorThrown) {
                        SetIsUpdateOrderQid(0);
                        alert("internet connection error, question was not saved.");
                    }
                });

            }

        });


    }

    function move_question_to_index(e, item, old_index,new_index) {


        e.stopPropagation();

        e.preventDefault();



        var new_data = {};
        new_data.groups = [...data.groups];


        new_data.groups.forEach(function (group_item, this_index) {


            if (group_item.gid == item.gid) {


                group_item.editable_questions = move_item_to_new_index(group_item.editable_questions,old_index,new_index);

            }

        });


        if(parseInt(item.qid))

            save_questions_order_to_db_and_set_data(new_data,item);
        else {
            setData(new_data);
        }

            }

            function move_item_to_new_index(in_elem,old_index,new_index){
                var new_objects = [];
                var moved_object = {};

                in_elem.forEach(function (object_item, this_index) {




                    if(old_index!==this_index){
                        new_objects.push(object_item);
                    } else {
                        moved_object = object_item;
                    }


                });

                var very_new_objects = [];

                new_objects.forEach(function (object_item, this_index) {




                    if(this_index==new_index){
                        very_new_objects.push(moved_object);
                    }
                    very_new_objects.push(object_item);


                });






            console.log("old : "+old_index);
            console.log("new : "+new_index);
            console.log("total length : "+in_elem.length);


            if((new_index+1)==in_elem.length){
                very_new_objects.push(moved_object);
            }

                return very_new_objects;
            }
        function move_answer_to_index(e, item, old_index,new_index) {



        console.log("move_up");



        item.answers = move_item_to_new_index(item.answers,old_index,new_index);

        set_question_in_data(item);

    }

    function remove_answer(e, item, index) {


        var new_answers = [];

        item.answers.forEach(function (answer_item, this_index) {

            if(index!==this_index){
                new_answers.push(answer_item);
            }



        });

        item.answers = new_answers;

        set_question_in_data(item);


    }

    function find_answer_index_non_present(answers){



        var answers_length = answers.length;

        answers.forEach(function (answer_item, this_index) {

    try {
        var this_num = parseInt(answer_item.answer_code.replace("A",""));

        if(this_num>answers_length){
            answers_length = this_num;
        }

    }
    catch(err){

    }


        });


        return answers_length+1;


    }

    function add_answer(e, item, index) {

        var new_answers = [];


        item.answers.forEach(function (answer_item, this_index) {


            new_answers.push(answer_item);


        });


        new_answers.push({
            answer:"",
            answer_code:"A"+find_answer_index_non_present(item.answers),
            is_term:"0"
        });

        item.answers = new_answers;
        set_question_in_data(item);

    }

    return (
        <div>

            <div className="allpagewrapper">
                <div className="leftbar">
                    <Logo />

                </div>

                <div className="pagecontents">
                    <h1>{data.data?.project_title ? "Data Collection - "+data.data?.project_title : ""}</h1>
                    <h4 className="page_sub_title">
                        <span className="">&nbsp;(Step 3 of 3)</span>
                    </h4>



                    <div className="upper_txt_explain form">
                        Your default template includes predefined data collection points for your convenience.
                        Where applicable, you have the option to edit/customize the collection questions to better suit your needs.
                        Additionally, you can add custom questions at an additional cost per question.
                    </div>





                    <div className="custom_setup_data">




                        {loading ? (
<PageLoading />
                        ) : error ? (
            <ConnectionError />
                            ) :
                            <div className="group_rows">
                                {data.groups.map((out_item) => (

                                    <div key={out_item.gid} className="group_row">

                    <div className="group_title">
                        <span className="group_name">
                        {out_item.group_name}
                        </span>
                        <span className={"group_ico "+out_item.icon}></span>
                    </div>
                                        <div className={"short_form_questions"+(out_item.show_edit ? " hide" : "")}>

                                            {out_item.editable_questions.map((inner_item) => (
                                    <div key={inner_item.qid} className="short_title">
                                        <span className="prpl bulleter">&#8226;</span> {inner_item.adtest_title}
                                        {inner_item.doesnt_qualify &&
                                        <span>

                                                                              <Popup on="hover" className="doesnt_qualify" trigger={    <img className="traffic_light" src="/traffic_light_red.svg" alt=""/>} position="right center">
    <div>{inner_item.doesnt_qualify}</div>
  </Popup>
                                                                         </span>
                                    }
                                    </div>

                                                ))}

                                            <div className="clear"></div>
                                        </div>




                                        {out_item.is_editable==1 ? (
                                            <div className={"questions_edit_pane"+(out_item.show_edit ? " show" : "")}>
                                                {out_item.editable_questions.length==0 ? <div>                                                                    <span    onClick={(e) => {
                                                    add_question(e, out_item.gid,0);
                                                }} className="quota_delete_add_ico add_question_icon"></span></div> : <div></div>}

                                                <Accordion id={"accordion_"+out_item.gid}   onChange={(e) => {
                                                    do_change(e,out_item.gid);


                                                }}>
                                                    {out_item.editable_questions.map((item,index) => (
                                                        <AccordionItem className={item.type =="" || item.type =="AS" ? "greyed-out" : ""} dangerouslySetExpanded={item.is_open} key={item.qid}>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    {item.adtest_title} <span className="red">{item.type =="" || item.type =="AS" ? " (Not Editable)" : ""}</span>
                                                                    {item.doesnt_qualify &&
                                                                        <span>

                                                                              <Popup on="hover" className="doesnt_qualify" trigger={    <img className="traffic_light" src="/traffic_light_red.svg" alt=""/>} position="right center">
    <div>{item.doesnt_qualify}</div>
  </Popup>
                                                                         </span>
                                                                    }



                                                                    {item.qid==is_updating_order_qid ?
<span className="purple_loader_question_update_order">
                                                            <PurpleLoader height={40} />

</span> : ''}


                                                                    <span    onClick={(e) => {

                                                                        if(index!==0)
                                                                            move_question_to_index(e, item,index,index-1);


                                                                    }}

                                                                             className={"move_question_span move_question_up"+(index==0 ? ' disabled' : '')}
                                                                             ><ArrowUpIco /></span>


                                                                    <span    onClick={(e) => {

                                                                        if(index!==(out_item.editable_questions.length-1))
                                                                            move_question_to_index(e, item,index,index+1);


                                                                    }}

                                                                             className={"move_question_span move_question_down"+(index==out_item.editable_questions.length-1 ? ' disabled' : '')}><ArrowDnIco /></span>



                                                                    <span    onClick={(e) => {


                                                                        if(item.type=="" || item.type=="AS") {
                                                                            alert("you cannot delete this question.");
                                                                            return;
                                                                        }
                                                                    delete_question(e, item.qid,item.gid);

                                                                    }} className="quota_delete_add_ico del_question_icon"></span>


                                                                    <span    onClick={(e) => {
                                                                        add_question(e, item.gid,index+1);
                                                                    }} className="quota_delete_add_ico add_question_icon"></span>

                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                {item.content}


                                                                <form   onSubmit={(e) => {
                                                    e.preventDefault();
                                                                    do_save_question(item);
                                                                }} id={"update_question_"+item.gid+"_"+item.qid} action="">


                                                                    <input type="hidden" name="question_order" value={index}/>
                                                                    <input type="hidden" name="sid" value={project_id}/>
                                                                    <input type="hidden" name="gid" value={item.gid}/>



                                                                    <div className="label_wrapper">
                                                                        <label htmlFor="lead_text">Question</label>

                                                                        <div>
                                                                    <input type="text" name="media_title" onChange={(e) => {
                                                                        set_question_value(e,item);
                                                                    }} value={item.media_title ? item.media_title : ''} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="label_wrapper">
                                                                        <label htmlFor="lead_text">Answer type</label>

                                                                        <div>

                                                                    <select name="type" id="type" value={item.type} onChange={(e) => {
                                                                        set_question_value(e,item);
                                                                    }}>


                                                                        <option value=""></option>

                                                                        <option value="L">Single</option>
                                                                        <option value="M">Multi</option>
                                                                        <option value="S">Free Text</option>
                                                                        <option value="ST">Star Rating</option>
                                                                        <option value="R">Slider</option>

                                                                    </select>
                                                                        </div>




                                                                        {item.type=='R' ? <div>
                                                                            <div className="label_wrapper">
                                                                                <label htmlFor="st_type">Range minimum</label>

                                                                                <div>
                                                                                    <input type="number" value={item.range_multi_min ? item.range_multi_min : 0}
                                                                                           name="range_multi_min"
                                                                                           onChange={(e) => {
                                                                                               set_question_value(e,item);
                                                                                           }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="label_wrapper">
                                                                                <label htmlFor="st_type">Range maximum</label>

                                                                                <div>
                                                                                    <input type="number" value={item.range_multi_limit ? item.range_multi_limit : 4}
                                                                                           name="range_multi_limit"
                                                                                           onChange={(e) => {
                                                                                               set_question_value(e,item);
                                                                                           }}
                                                                                    />
                                                                                </div>
                                                                            </div>



                                                                        </div>: <div></div>}

                                                                        {item.type=='ST' ? <div>
                                                                                <div className="label_wrapper">
                                                                                    <label htmlFor="st_type">Rating limit</label>

                                                                                    <div>
                                                                                        <input type="number" value={item.rating_limit ? item.rating_limit : 2}
                                                                                            name="rating_limit"
                                                                                               onChange={(e) => {
                                                                                                   set_question_value(e,item);
                                                                                               }}
                                                                                        />
                                                                                    </div>
                                                                                </div></div>: <div></div>}
                                                                        {item.type=='L' || item.type=='M' ? <div>


                                                                            <div className="answers_header">Answers</div>
                                                                            {item.answers.map((answer,in_index) => (
                                                                                <div key={"A"+in_index} className="answer_row">



                                                                                    <input type="hidden" name={"answer["+(answer.answer_code ? answer.answer_code : "A"+(in_index+1))+"][is_term]"} value={answer.is_term} />
                                                                                    <span

                                                                                        className={"dontcontinue"}>
                                                                                        <input
                                                                                            onClick={(e) => {


                                                                                                if(answer.is_term=="1") {
                                                                                                    answer.is_term = "0";
                                                                                                } else {
                                                                                                    answer.is_term = "1";
                                                                                                }
                                                                                                console.log(item);

                                                                                                set_question_in_data(item);

                                                                                            }}
                                                                                            checked={answer.is_term=="1"}

                                                                                            id={"is_term_"+(answer.answer_code ? answer.answer_code : "A"+(in_index+1))} type="checkbox"/>
                                                                                        <label htmlFor={"is_term_"+(answer.answer_code ? answer.answer_code : "A"+(in_index+1))} className="txtx">
                                                                                        Doesn’t Qualify
                                                                                        </label>
                                                                                    </span>

                                                                                                                           <span    onClick={(e) => {
                                                                                                                               remove_answer(e, item,in_index);
                                                                                                                           }} className="quota_delete_add_ico remove_answer"></span>

                                                                                    <span

                                                                                        onClick={(e) => {

                                                                                            if(in_index!==0)
                                                                                                move_answer_to_index(e, item,in_index,in_index-1);

                                                                                        }}
                                                                                        className={"move_answer_up move_answer_arrow"+(in_index==0 ? ' disabled' : '')}><ArrowUpIco /></span>
                                                                                    <span
                                                                                        onClick={(e) => {
                                                                                        if(in_index!==(item.answers.length-1))
                                                                                                move_answer_to_index(e, item,in_index,in_index+1);

                                                                                        }}

                                                                                        className={"move_answer_down move_answer_arrow"+(in_index==item.answers.length-1 ? ' disabled' : '')}><ArrowDnIco />

                                                                                    </span>

                                                                                    <input onChange= {(e) => {

                                                                                        var new_value = e.target.value;
                                                                                        answer.answer = new_value;
                                                                                        set_question_in_data(item);

                                                                                    }}
                                                                                           type="text" name={"answer["+(item.answer_code ? item.answer_code : "A"+(in_index+1))+"][answer]"} value={answer.answer ? answer.answer : ''}/>

                                                                                </div>

                                                                            ))}


                                                                            <div className="clear"></div>
                                                                            <div className="add_answer">
                                                                               <span  onClick={(e) => {
                                                                                   add_answer(e, item,index);
                                                                               }} className="addanswer">
                                                                                Add Answer
                                                                               </span>
                                                                            </div>

                                                                        </div> : <div></div>}


                                                                    </div>




                                                                    <input type="hidden" name="qid" value={item.qid}/>

                                                                    <div className="submitor">

                                                                <button>Save</button>
                                                                    </div>

                                                                </form>


                                                                {item.errors && item.errors.map((error,index) => (
                                                                    <div className="error_div" key={index}>{error}</div>
                                                                ))
                                                                }

                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                    ))}
                                                </Accordion>

                                            </div>


                                        ) : <div></div>}
    <div className="clear"></div>
                                        {out_item.is_editable==1 ? (
                                            <div>

                                        <div onClick={(e) => {
                                            show_custom_setup_edit(e,out_item.gid);
                                        }} className="edit_questions">
                                            {out_item.show_edit ? 'Close' : 'Edit'}
                                        </div>


                                                {out_item.max_questions<=out_item.editable_questions.length ?
                                    <div id="more_warning">
                                        * You’ve reached the question limit for this section. Each additional question beyond this limit will incur a {out_item.one_question_cost} charge.
                                    </div> : ''}

                                            </div>
                                         ) : <div></div>}

                                    <div className="clear"></div>





                                        <div className="clear"></div>

                                    </div>
                                ))
                                }


                                <div className="calculator_more_spacing">

                                <Calculator product_price={adtest_amount_left_to_pay} display="true" />

                                </div>
                            <div className="submitor">
                                <button   onClick={(e) => {
                                    navigate('/confirmation/'+project_id);

                                }}>Continue</button>
                            </div>
                                <div id="more_spacing"></div>

                            </div>




                        }














                    </div>
                </div>
            </div>
        </div>

    )
};
export default  CustomSetup