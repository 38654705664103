//Home.js
import React, {useEffect, useState} from 'react'
import $ from 'jquery';
import Navbar from './Navbar'
import {Link, useParams} from "react-router-dom";
import './App.css';
import './Scripts.js';
import * as scripts from "./Scripts";
import Logo from './components/Logo'
import { useForm } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";

import { useNavigate } from 'react-router-dom';
import Calculator from "./components/Calculator";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'



import Popup from "reactjs-popup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import styled from 'styled-components'

import {StopCircle} from "@styled-icons/boxicons-regular/StopCircle";
import {Rocket} from "@styled-icons/ionicons-sharp/Rocket";

import PurpleLoader from './components/PurpleLoader';
import ConnectionError from "./components/ConnectionError";
import PageLoading from "./components/PageLoading";






const ico_height = '25px';



const StopIco = styled(StopCircle)`
      height:18px
    `;


const InfoRocketIco = styled(Rocket)`
      height:25px
    `;


const Confirmation = () => {

    const { register,trigger, control,handleSubmit, formState: { errors } } = useForm();
    const [is_submitting, SetSubmitting] = useState(false);
    const [did_update_error, SetUpdateError] = useState(false);





    const navigate = useNavigate();
    scripts.on_before_load_redirect_inside(navigate);
    const { project_id } = useParams();

    const [data, setData] = useState([]);

    const [project_status_update_content_text, SetProjectUpdateText] = useState('');
    const [survey_publish_status, SetPublishStatus] = useState('');






    const [adtest_amount_left_to_pay, setCalculatedPrice] = useState('');
    const [project_title, setProjectTitle] = useState('');
    const [base_project_title, setBaseProjectTitle] = useState('');

    const [num_completes, setNumCompletes] = useState(0);

    const [num_completes_base, setNumCompletesBase] = useState(0);


    useEffect(() => {
        scripts.fix_page_contents_class_height();

    }, [])



    const fetchData = async () => {

        try {
            const response = await fetch(window.api_url+'adtest/main/get_adtest_confirmation_data?project_id='+project_id+'&'+scripts.get_api_url_suffix()); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const data = await response.json();


            if(data.survey_data.publish){
                SetPublishStatus(data.survey_data.publish);
            }


            setData(data);



            if(data.survey_data.amount_left_to_pay){
                setCalculatedPrice(data.survey_data.amount_left_to_pay);
            }
            if(data.survey_data.survey_title){
                setProjectTitle(data.survey_data.survey_title);
                setBaseProjectTitle(data.survey_data.survey_title);
            }
            if(data.survey_data.sub_total){
                setNumCompletesBase(data.survey_data.sub_total);
                setNumCompletes(data.survey_data.sub_total);
            }

            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }

    }


    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        fetchData();
    }, []);


    function project_data_row(label, text) {


        return <div className="data_row">
            <span className="lbl">{label}</span>
            |
            <span className="txt">{text}</span>
        </div>
    }

    function full_gender_name(gender) {
        if(gender=='m'){
            return 'Male';
        }
        if(gender=='f'){
            return 'Female';
        }

        if(gender=='nb'){
            return 'Nonbinary';
        }

    }

    function do_view_edit_box(type) {
        return                                     <div
            onClick={(e) => {


                if(survey_publish_status=='Y'){
                    alert("project must be stopped in order to make changes.");
                    return;
                }
                if(type=='details') {



                    navigate('/editsetup/'+project_id);
                }

                if(type=='quotas') {


                    navigate('/editproject/'+project_id+"?confirm=1");



                }

                if(type=='questions') {
                    navigate('/customsetup/'+project_id);


                }


            }}


            className="view_edit">


            {1==1 ?
            <div className="in_wrapper">
            <div className="in_wrap">

            <span className="ico"></span>
            <span className="txt">
                                            View/<br />Edit
                                        </span>
            </div>
            </div>

                : <div></div>}

        </div>;
    }

    function do_change_survey_status(publish) {


        $.ajax({
            url: window.api_url+'adtest/main/adtest_publish_or_unpublish_survey?active='+publish+"&project_id="+project_id+"&"+scripts.get_api_url_suffix(),
            type: 'get',
            contentType: false,
            processData: false,
            success: function(response){

                try {
                    var json = $.parseJSON(response);



                    if(json.error){
                        SetUpdateError(true);
                        if(json.error=='not_enough_funds'){
                            SetProjectUpdateText("you do not have enough credits, please contact us.");

                        }
                else
                        if(json.error=='max_submissions_already_recorded'){
                            SetProjectUpdateText("project has reached the required number of completes, please increase or start a new project");

                        } else
                        if(json.error=='survey_must_have_targeting'){
                            SetProjectUpdateText("Project must have targeting to launch. Please edit targeting and try again.");

                        }
                        return;
                    }
                    if(json.success){

                        if(publish){
                            SetPublishStatus('Y');
                            SetProjectUpdateText("Project is running.");
                        } else {
                            SetPublishStatus('N');
                            SetProjectUpdateText("Project is paused.");
                        }


                        // navigate('/projects');
                    }

                }
                catch(err){
                    console.log(err);
                }

            },
        });


    }

    function project_update_status_close_cb(cb) {
        if(did_update_error) {
            SetUpdateError(false);
            cb();
        } else {

            if(survey_publish_status=='Y')
                navigate('/projects'); else
                    cb();
            SetUpdateError(false);

        }
    }

    return (
        <div>

            <div className="allpagewrapper">
                <div className="leftbar">
                    <Logo />

                </div>

                <div className="pagecontents">
                    <h1>{data.survey_data?.survey_title ? "Launch Confirmation - "+data.survey_data?.survey_title : ""}</h1>

                    {loading ? (
                        <PageLoading />
                    ) : error ? (
                <ConnectionError />
                    ) :
                        <div className="confirmation_data">

                            <Calculator product_price={adtest_amount_left_to_pay} display="true" />



                            <div className="project_data_box">

                                <div className="heading"><span className="menu_icon setup_icon"></span> Setup</div>


                                <div className="data_boxes_wrapper">

                                    {do_view_edit_box('details')}

                                <div className="data_boxes_left">

                                <div className="data_box">


                                    {project_data_row("Project name",project_title)}


                                    {project_data_row("Product",data.survey_data.product_name)}


                                    {project_data_row("Country",data.survey_data.country_code)}




                                </div>
                                </div>
                                <div className="data_boxes_right">

                                    <div className="data_box">



                                        {project_data_row("Number of completes",data.survey_data.num_achieved+"/"+num_completes)}

                                        {project_data_row("Media type",data.survey_data.media_type)}



                                    </div>

                                </div>



                                    <div className="clear"></div>
                                </div>

                            </div>




                            <div className="project_data_box">

                                <div className="heading"><span className="menu_icon targeting_icon"></span> Targeting</div>


                                <div className="data_boxes_wrapper targeting">

                                    {do_view_edit_box('quotas')}


                                    <div className="data_boxes_left veryfull">

                                        <div className="data_box">




                                            {data.quotas?.age && data.quotas?.age.length>0 ?
                                            <div className="data_row">
                                                <span className="lbl">Ages</span>
                                                |
                                                <span className="txt">
                                                    {data.quotas?.age.map((item,index) => (
                                                            <span key={index} className="textor">
                                                                {index>0 ? ' , ' : ''}{item.age_from}-{item.age_to}
                                                            </span>
                                                    ))
                                                    }

                                                </span>
                                            </div> : <div></div>}


                                            {data.quotas?.gender && data.quotas?.gender.length>0 ?
                                                <div className="data_row">
                                                    <span className="lbl">Gender</span>
                                                    |
                                                    <span className="txt">
                                                    {data.quotas?.gender.map((item,index) => (
                                                        <span key={index} className="textor">
                                                                {index>0 ? ' , ' : ''}{full_gender_name(item.gender)}
                                                            </span>
                                                    ))
                                                    }

                                                </span>
                                                </div> : <div></div>}


                                            {data.quotas?.location && data.quotas?.location.length>0 ?
                                                <div className="data_row">
                                                    <span className="lbl">Location</span>
                                                    |
                                                    <span className="txt">
                                                    {data.quotas?.location.map((item,index) => (
                                                        <span key={index} className="textor">
                                                                {index>0 ? ' , ' : ''} {item.location_type} : {item.location_val}
                                                            </span>
                                                    ))
                                                    }

                                                </span>
                                                </div> : <div></div>}


                                        </div>
                                    </div>
                                    <div className="data_boxes_right">

                                        <div className="data_box">

                                        </div>

                                    </div>



                                    <div className="clear"></div>
                                </div>

                            </div>


                            <div className="project_data_box">

                                <div className="heading"><span className="menu_icon data"></span>Data Collection</div>


                                <div className="data_boxes_wrapper noflex">

                                    {do_view_edit_box('questions')}


                                    <div className="data_boxes_left full">

                                        <div className="data_box">




                                            {data.groups?.map((item,idx) => (

                                            <div key={idx} className="data_row groups_data_row">
                                                <Popup className="confirmation" trigger={                                                <span className="lbl clickable"><span className="expand_ico">

                                                    {item.num_exit_q &&
                                                        <span className="exit_bubble">{item.num_exit_q}</span>
                                                    }

                                                </span>&nbsp;<span className="textas"> {item.group_name}</span></span>} position="bottom left">
                                                    <div>
                                                        {item.questions?.map((question,idx) => (
                                    <span key={idx} className={"question"+(question.does_question_have_an_exit=="1" ? " red" : "")}>{idx>0 ? " , " : ""}{question.adtest_title}</span>
                                                        ))
                                                        }

                                                    </div>
                                                </Popup>

                                            </div>
                                            ))
                                            }




                                        </div>
                                    </div>
                                    <div className="data_boxes_right">

                                        <div className="data_box">



                                        </div>

                                    </div>



                                    <div className="clear"></div>
                                </div>

                            </div>

            <div className="clear"></div>


                            <div className="stop_resume_btn">

                                <Link to={"/reporting/"+project_id}>
  <span className="action_btn reporting">
              <span className="reporting_ico"></span>  Reporting
                                        </span></Link>


                                <Popup

                                    className="project_status_confirm_update"

onOpen={() => {

    do_change_survey_status(survey_publish_status=='N' ? 1 : 0)
}}
                                    trigger={

                                        survey_publish_status=='N' ?
                                            <span className="action_btn start">
              <InfoRocketIco />  Start
                                        </span>
                                            :
                                            <span className="action_btn stop">
                <StopIco /> Stop
                                </span>

                                    }
                                    modal
                                    nested
                                >
                                    {close => (
                                        <div className="inmodal project_update_status_modal">
                                            <button className="close"                                                         onClick={() => {

                                                            project_update_status_close_cb(close);
                                            }}>
                                                &times;
                                            </button>
                                            <div className="header">Confirmation</div>
                                            <div className="content">
                                                {
                                                    project_status_update_content_text=='' ?
                                                        <PurpleLoader height={40} /> : project_status_update_content_text
                                                }
                                            </div>
                                            <div className="actions">

                                                <div className="in_actions">


                                                    <button
                                                        className="button select_product"
                                                        onClick={() => {

                                                            project_update_status_close_cb(close);
                                                        }}
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Popup>



                            </div>
                            <div className="clear"></div>

                        </div>

                    }

                </div>
            </div>
        </div>

    )
}

export default Confirmation;